import React  from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Availability from "../../pages/availability-book"
import Home from "../../pages/home"

const Page = ({ pageContext }) => {

  const page = pageContext.page

  // Home Page
  if (page.title === `Home.`){
    return(
      <Home />   
    )
  }

  // Availability & Booking
  if (page.title === `Availability & Book`){
    return(
      <Availability />   
    )
  }

  else {
    return (
      <Layout>
        <SEO title={page.title} />
        <h1>{page.title}</h1>
        <div dangerouslySetInnerHTML={{__html: page.content}} />
      </Layout>
    )
  }
}

export default Page